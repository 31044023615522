//Custom Input
.custom-input {
  margin: 4px 2px 4px 2px;
  background-color: var(--ion-color-light);
  border: 1px solid var(--ion-color-light);
  border-radius: 8px 8px 8px 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 0px;

  ion-item {
    --background: var(--ion-color-light);
    --border-style: none;
    --padding-start: 0px;
  }

  &:focus-within { //!!!!!!
    border: 1px solid var(--ion-color-primary);
  }
}

.custom-inputtitle {
  margin-bottom: 8px;
  margin-left: 8px;
}

.custom-inputerror {
  border: 1px solid var(--ion-color-danger);

  ion-item {
    ion-input {
      color: var(--ion-color-danger);
    }
  }
}

/*
<ion-text><p class="custom-inputtitle">E-Mail</p></ion-text>
<div #input2 id="inp2id" class="custom-input custom-inputerror">
  <ion-item>
    <ion-input placeholder="E-Mail" (ionFocus)="removeError(this.input2)"></ion-input>
  </ion-item>
</div>

@ViewChild('input2') input2: HTMLDivElement;
document.getElementById('inp2id').classList.add('custom-inputerror');

removeError(vc: HTMLElement) {
  this.error = null;
  vc.classList.remove('custom-inputerror');
}
*/



//Custom Error Message
.custom-errormessage {
  background-color: var(--ion-color-danger-tint);
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 4px 2px 4px 2px;
  border: 1px solid var(--ion-color-danger-shade);
  border-radius: 8px;
  color: white;

  ion-item {
    --border-radius: 8px;
    --color: white;
    --background: var(--ion-color-danger-tint);
  }
}
